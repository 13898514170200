const kansasRouter = {
  route: null,
  name: null,
  title: '堪萨斯血检',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-jiemiansheji',
  isElementIcon: false,
  filePath: 'view/kansas/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '二维码管理',
      type: 'view',
      name: 'KansasQrcodeList',
      route: '/kansas/list',
      filePath: 'view/kansas/list.vue',
      inNav: true,
      icon: 'iconfont icon-jiemiansheji',
      isElementIcon: false,
    },
  ],
}

export default kansasRouter
