const chipRouter = {
  route: null,
  name: Symbol('center'),
  title: '芯片号管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/chip/', // 文件路径
  order: null,
  inNav: true,
  permission: ['芯片号管理'],
  children: [
    // 芯片号管理
    {
      title: '芯片号列表',
      type: 'view',
      name: Symbol('center'),
      route: '/chip/chiplist/index',
      filePath: 'view/chip/chiplist/index.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      permission: ['芯片号列表'],
    },
    // 堪萨斯芯片爬虫
    {
      title: '堪萨斯爬虫',
      type: 'view',
      name: Symbol('center'),
      route: '/chip/kss-spider/index',
      filePath: 'view/chip/kss-spider/index.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      permission: ['堪萨斯爬虫列表'],
    },
  ],
}

export default chipRouter
